/**
 * Overwrites a property on newObj if it is empty with the value from originalObj.
 */
export default function resetProp(propName: string, newObj?: object, originalObj?: object) {
    if (!(originalObj && newObj)) {
        return;
    }
    const val = newObj[propName as keyof typeof newObj];
    if (val === undefined || val === null || `${val}`.length === 0) {
        // @ts-ignore
        newObj[propName] = originalObj[propName];
    }
}
